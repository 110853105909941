<script lang="ts" setup>
defineEmits<{ (e: 'clickMenu'): void }>()

const { status } = useAuth()
const isMobileScreen = useIsMobileScreen()
const { accessCodeSession, terminateSession } = useCustomerModuleAccessCodeSession()
</script>

<template>
  <div class="fixed top-0 w-screen bg-hos-blue-dark py-4 border-b border-white/10 z-30">
    <div class="flex items-center gap-4 justify-between">
      <NuxtLink to="/my-hos">
        <img src="/branding/Logo-Hanselmann-ohne Slogan_weiss.png" class="h-5 sm:h-8">
      </NuxtLink>

      <div class="flex items-center gap-2">
        <CustomerModuleLayoutLanguageSelector :display-text="!isMobileScreen" />

        <!-- Regular authenticated user -->
        <template v-if="status === 'authenticated'">
          <div class="flex items-center gap-1">
            <NuxtLink to="/auth/logout">
              <CustomerModuleLayoutLogoutButton :is-mobile-screen="isMobileScreen" />
            </NuxtLink>
          </div>
          <n-button type="primary" icon-placement="right" @click="$emit('clickMenu')">
            <template #icon>
              <Icon name="material-symbols:menu" />
            </template>
            <template v-if="!isMobileScreen">
              {{ $t('customerModule.navigation.menu.title') }}
            </template>
          </n-button>
        </template>

        <!-- Access code session user -->
        <template v-else-if="accessCodeSession">
          <CustomerModuleLayoutLogoutButton :is-mobile-screen="isMobileScreen" @click="terminateSession" />
        </template>

        <!-- Neither -->
        <NuxtLink v-else to="/auth/login">
          <n-button type="primary">
            <template #icon>
              <Icon name="material-symbols:login" />
            </template>
            {{ $t('customerModule.navigation.login.title') }}
          </n-button>
        </NuxtLink>
      </div>
    </div>
  </div>
</template>
